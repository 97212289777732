<template>
  <router-link
    :to="{ name: 'all_qna' }"
    class="block mb-3 text-lg tw-font-semibold"
    v-if="isAdminPriti()"
  >
    <i class="mr-2 pi pi-arrow-left"></i>
    Kembali
  </router-link>

  <div class="card">
    <div class="text-xl font-medium text-black mb-4">
      <p>Tanya Jawab</p>
    </div>
    <div class="grid">
      <div class="col-12 lg:col-11 xl:col-9 tw-mb-5" v-if="load_data">
        <Skeleton height="79px" class="mb-3"></Skeleton>
        <Skeleton height="79px" class="mb-3"></Skeleton>
      </div>
      <template v-else>
        <div class="col-12 lg:col-11 xl:col-9 tw-mb-5">
          <Accordion lazy class="w-full pl-4 mb-3" v-for="(question, index) in data" :key="`_${index}`">
            <AccordionTab>
              <template #header>
                <div question class="flex w-full">
                  <div class="tw-flex-shrink-0 tw-bg-gray-200 tw-rounded-full tw-w-12 tw-h-12 flex align-items-center tw-justify-center">
                    {{ getInitial(question.name ? question.name : question.email) }}
                  </div>
                  <div class="ml-3 w-full">
                    <div class="flex align-item-center tw-justify-between">
                      <h6 class="tw-font-semibold mb-1">
                        {{ question.name ? question.name : question.email }}
                      </h6>
                      <small class="tw-text-gray-500">
                        {{ dateFormat(question.created_at) }}
                      </small>
                    </div>
                    <p class="tw-text-gray-600">
                      {{ question.content }}
                    </p>
                  </div>
                </div>
              </template>
              <div answer class="tw-pl-16 tw-pr-10 tw-pt-2 tw-pb-5">
                <div class="flex tw-mb-8" v-for="(answer, key) in question.replied" :key="`answer${key}`">
                  <div class="tw-flex-shrink-0 tw-bg-gray-200 tw-rounded-full tw-w-12 tw-h-12 flex align-items-center tw-justify-center">
                    {{ getInitial(answer.name ? answer.name : answer.email) }}
                  </div>
                  <div class="ml-3 w-full">
                    <div class="flex align-item-center tw-justify-between">
                      <h6 class="tw-font-semibold mb-1">
                        {{ answer.name ? answer.name : answer.email }}
                      </h6>
                      <small class="tw-text-gray-500">
                        {{ dateFormat(answer.created_at) }}
                      </small>
                    </div>
                    <p class="tw-text-gray-600">
                      {{ answer.content }}
                    </p>
                  </div>
                </div>
                <div class="flex">
                  <div class="tw-flex-shrink-0 tw-bg-gray-200 tw-rounded-full tw-w-12 tw-h-12 flex align-items-center tw-justify-center">
                    {{ getInitial(getUser().email) }}
                  </div>
                  <form @submit.prevent="doSendReply(index, question.id)" class="ml-3 w-full">
                    <Textarea
                      :rows="rows[index] ? rows[index] : 1"
                      v-model="replies[index]"
                      class="w-full"
                      placeholder="Jawaban"
                      @focus="showReplyField(index)"
                      :disabled="loading_submit[index]"
                    />
                    <div class="text-right mt-3" v-if="rows[index] && rows[index] === 4" >
                      <Button
                        label="Batal"
                        type="submit"
                        class="p-button-danger mr-3 px-5"
                        :disabled="loading_submit[index]"
                        @click="hideReplyField(index)"
                      />
                      <Button type="submit" :disabled="loading_submit[index]" class="btn-danger font-bold px-5">
                        <span v-if="!loading_submit[index]">Kirim</span>
                        <i class="pi pi-spin pi-spinner" style="font-size: 1.5rem" v-else></i>
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import moment from "moment";
import { onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import { useAuthRole } from "@/composables/auth";
import { useQnA } from "@/composables/qna";

const route = useRoute();
const { salonId, getUser } = useAuthRole();
const { isAdminPriti } = useAuthRole();
const { data, getList, postReply } = useQnA();

const load_data = ref(false);
const rows = ref([]);
const form = ref({});
const replies = ref([]);
const loading_submit = ref([]);

const doSendReply = async (index, parentID) => {
  loading_submit.value[index] = true;
  form.value = {
    user_id: getUser().user_id,
    salon_id: parseInt(route.params.id || salonId.value),
    parent_id: parentID,
    content: replies.value[index],
  };

  await postReply(form.value);
  loading_submit.value[index] = false;
  replies.value[index] = "";

  hideReplyField(index);
  data.value[index].replied.push({
    ...form.value,
    email: getUser().email,
    created_at: moment(),
  });
};

const getInitial = (name) => {
  let arr = name.split(" ");
  return arr[0][0];
};

const dateFormat = (date) => {
  return moment(date).locale("id_ID").format("DD MMM YYYY");
};

const showReplyField = (index) => {
  rows.value[index] = 4;
};

const hideReplyField = (index) => {
  rows.value[index] = 1;
};

onBeforeMount(async () => {
  load_data.value = true;
  await getList(route.params.id || salonId.value);

  load_data.value = false;
});
</script>

<style lang="scss" scoped>
</style>
